import React, { useState, useContext, useEffect } from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { api_get } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
import { getApiHost } from "../../config.js";

export const Courses = (props) => {
  let context = useContext(PersonContext);
  let [ courses, setCourses ] = useState([]);
  let [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    if(context?.person?.personId?.externalId == null) {
        return;
    }
    api_get(
        "/persons/" + context.person.personId.externalId + "/courses"
    ).then((resp) => {
      setCourses(resp.courses);
      setLoading(false);
    });
}, [context]); // eslint-disable-line


  return (
    <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <>
          { courses.length > 0 && (
            <ul className="list">
              {
                courses.map((item, index) => {
                  return <ListItemCourse item={item} key={index} />;
                })
              }
            </ul>
          )}
            { courses.length === 0 && (
                <div className="mt-3">
                <p>{i18next.t("Settings.CoursesNoBokings")}</p>
                </div>
            )}
          </>
        )
      )}
    </Translation>
    );
};

const ListItemCourse = (props) => {
    let context = useContext(PersonContext);
    let item = props.item;
    return (
      <li className="course-item-holder mb-40">
        <div>
          <div className="course-item">
            <h4>{item.title}</h4>
            
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CoursePeriod")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.startDate} - {item.stopDate}</span>
                </div>                
            </div>
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseNumberOfTimes")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.clipcard.clipsInitial} st</span>
                </div>                
            </div>
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseLeader")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.instructors}</span>
                </div>                
            </div>
            <div className="info-row mb-4">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseCenter")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock"><a target="_blank" href={'https://www.actic.' + item.center.address_country.toLowerCase() + item.center.url}>{item.center.name}</a></span>
                </div>                
            </div>

            <div className="body-text-small mb-2">{i18next.t("Settings.CourseTimes")}</div>

            {
              item.schedule.map((item, index) => {
                return (
                <div className="info-row" key={index}>
                <div>
                    <span className="caption-text color-rock">{item.date}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.time}</span>
                </div>                
                </div>
                )
              })
            }

            <div className="mt-4 mb-4 list list-toggle">
                <a className="link-download-invoice color-primary" target="_blank" href={ getApiHost() + "/persons/"+context.person.personId.externalId + "/receipt/" + item.clipcard.invoiceLineId.subId + "/" + item.clipcard.invoiceLineId.center + "/" + item.clipcard.invoiceLineId.id + "/?AccessToken="+context.accessToken}>Ladda ner kvitto</a></div>
        </div>
        </div>
      </li>
    );
  }