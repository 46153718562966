import React, { useState, useContext, useEffect } from "react";
import { Container } from "../Components.js";
import { TopContent } from "../TopContent.js";
import { Translation } from "react-i18next";
import PersonContext from "../../PersonContext.js";
import { NavTop } from "../Nav.js";
import Breadcrumbs from "../Breadcrumb.js";
import i18next from "i18next";
import { api_get, api_post } from '../../API.js';
import { Loading } from '../Components.js';

export const PersonalCampaignsPage = (props) => {
  let context = useContext(PersonContext);
  let [ campaigns, setCampaigns ] = useState([]);
  let [ isLoading, setLoading ] = useState(true);


  useEffect(() => {

    if (typeof context.person.personId === 'undefined') {
      return;
    }

    document.title =
      i18next.t("Headline.Settings") + " - " + i18next.t("SiteName");

      api_get('/persons/' + context.person.personId.externalId + '/campaigns')
      .then((resp) => {
          if(resp.personalCampaigns !== undefined) {
            if(resp.personalCampaigns !== undefined) {
              setCampaigns(resp.personalCampaigns)
            }
          }
        
        setLoading(false);
      });

  }, [context]); // eslint-disable-line

  

  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2="Personliga Kampanjer" />
          <Container>
             <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <>
            { campaigns.length > 0 && (
            <ul className="list">
                {
                campaigns.map((item, index) => {
                    return <CampaignItem item={item} key={index} />;
                })
                }
            </ul>
            )}
            { campaigns.length === 0 && !isLoading && (
                <div className="mt-3">
                <p>Inga personliga kampanjer just nu.</p>
                </div>
            )}
          </>
        )
      )}
    </Translation>
          </Container>
         
        </>
      )}
    </Translation>
  );
};

const CampaignItem = (props) => { 
  let [ isLoading, setLoading ] = useState(false);
  let [ error, setError ] = useState(false);
  let item = props.item;
  let context = useContext(PersonContext);
  return (
  <li className="campaign-item-holder mb-40">
      <div>
        <div className="campaign-item">
          <img src={item.image} width={item.imageWidth} height={item.imageHeight}/>
          <div
              className="body-text-regular mb-4" 
              dangerouslySetInnerHTML={{
              __html: item.text,
          }}>
          </div>

          {item.status === 'used' && (
               <div
               className="body-text-regular color-blue" 
               dangerouslySetInnerHTML={{
               __html: item.usedText,
           }}>
           </div>
          )}

          {item.type === 'url' && !isLoading && (
              <div className="btn secondary-btn color-secondary" onClick={() => {
                  window.open(item.link, '_blank');
              }}>Till kampanjen</div>
          )}

          {(item.type === 'mail' || item.type === 'list') && !isLoading && item.status !== 'used' && (
              <div className="btn secondary-btn color-secondary" onClick={() => {
                  setError(false);
                  setLoading(true);
                  api_post('/persons/' + context.person.personId.externalId + '/campaigns/' + item.campaignId + '/use', {
                      personId: context.person.personId
                  })
                      .then((resp) => {
                      if(resp.success) {
                          item.status = 'used';
                      }
                      setLoading(false);
                  })
                  .catch(() => {
                      setLoading(false);
                      setError('Ett fel uppstod, försök igen senare.');
                  });
              }}>Aktivera kampanj</div>
          )}

          {isLoading && (
              <div className="d-flex justify-content-center mt-4 mb-4"><Loading>Laddar...</Loading></div>
          )}

          {error && (
              <div className="body-text-regular color-red mt-4">{ error }</div>
          )}
      </div>
      </div>
   </li>
  );
}