import React from "react";
import { getApiHost } from '../../config.js'
import { Translation, Trans } from "react-i18next";
import i18next from 'i18next';
import { api_post, api_get, api_delete } from "../../API";
import PersonContext from '../../PersonContext.js';
import { Alert } from "../Alert";
import { Loading } from '../Components.js';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

class InputField extends React.Component {
    constructor(props) {
        super();

        this.state = {
            value: ''
        }
    }

    getClassName = () => {
        let classes = [];
        switch (this.props.type) {
            case 'radio':
                classes.push('form-check-input');
                break;
            default:
                classes.push('form-control');
                break;
        }

        let empty = (this.state.value.length > 0 ? false : true);

        if ((this.props.hasSubmitted || ! empty) && this.props.isInvalid === true) {
            classes.push('is-invalid');
        } else if (! empty && this.props.isInvalid === false) {
            classes.push('is-valid');
        }

        return classes.join(' ');
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChange(e);
    }

    render = () => {
        switch (this.props.type) {
            case 'radio':
                return this.renderRadio();
            default:
                return this.renderText();
        }
    }

    renderText = () => {
        return (
            <div>
               {this.props.label && 
                    <label className="form-label body-text-small">
                        {this.props.label}:
                    </label>
                }
                <input 
                    type={this.props.type}
                    className={this.getClassName()}
                    name={this.props.name}
                    id={this.props.name}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onChange={this.onChange}
                    autoComplete="off"
                    placeholder={this.props.placeholder}
                />
                {
                    this.props.invalidMessage
                    ?
                    (
                        <div className="invalid-feedback body-text-small">
                            {this.props.invalidMessage}
                        </div>
                    )
                    : null
                }
            </div>
        )
    }

    renderRadio = () => {
        return (
            <div>
                <input 
                    type="radio"
                    className={this.getClassName()}
                    name={this.props.name}
                    value={this.props.value}
                    id={this.props.name + this.props.value}
                    disabled={this.props.disabled}
                />
                <label htmlFor={this.props.name + this.props.value} className="form-check-label">
                    {this.props.label}
                </label>
            </div>
        )
    }
}

export class AutogiroForm extends React.Component {

    static contextType = PersonContext;

    constructor(props) {
        super();

        this.originalState = {
            hasSubmitted: false,
            isStep1Submitting: false,
            isStep1Disabled: false,
            isStep1Done: false,
            isStep2Submitting: false,
            isStep2Disabled: true,
            isStep2Done: false,
            isStep2CancelSubmitting: false,
            isSignAborted: false,
            isSignCompleted: false,
            pdfPreview: {
                zoomLevel: null,
                image: null,
                imageLoaded: false,
                imageHeight: null,
                downloadUrl: null,
            },
            eftGuid: null,
            form: {
                personalNo: '',
                bankName: '',
                bankRegNumber: '',
                bankAccountNumber: '',
                signedBy: null,
                signedByOtherType: null,
                accountOwnerPersonalNo: '',
                accountOwnerFirstName: '',
                accountOwnerLastName: '',
                accountOwnerEmail: '',
                accountOwnerStreetAddress: '',
                accountOwnerPostalCode: '',
                accountOwnerPostalAddress: '',
                //companyName: '',
                //companyIdentityNo: '',
                checkAccountOwnerSignee: false,
                checkTerms: false,
                //checkOtherEmailContact: false,
            },
            invalid: {},
            invalidMessage: {},
            signStatusMessage: null,
            bankIdLink: false,
        }

        this.state = this.originalState;

        this.refCheckAccountOwnerSignee = React.createRef();
        this.refCheckTerms              = React.createRef();
        this.refPDFPreview              = React.createRef();

        // All invalid on load.
        Object.keys(this.state.form).forEach((inputName) => {
            this.state.invalid[inputName] = true;
        });

        // Except optional values.
        //delete this.state.invalid['checkOtherEmailContact'];
    }

    /**
     * Component did mount.
     */
    componentDidMount = () => {
        // Personal number is not required if we have it in the context.
        if (typeof this.context.person.socialSecurityNumber === 'string') {
            delete this.state.invalid['personalNo'];
        }
    }

    /**
     * Reset form statuses. Resets to original state except for the form data.
     */
    resetFormStatus = () => {
        let originalState = this.originalState;
        originalState.form = this.state.form;
        this.setState();
    }
    
    /**
     * On change event for the forms fields.
     */
    formFieldOnChange = (e) => {        
        let input       = e.target;
        let newState    = this.state;
        let valid       = true;
        let oldValue    = this.state.form[input.name];
        let isBackspace = false;

        // We use onChange but wanna know if this prob is a back space just to help with personal no input.
        if (typeof input.value === 'string' && typeof oldValue === 'string') {
            isBackspace = (input.value.length < oldValue.length ? true : false);
        }
        
        // Default validation.
        if ((input.type === 'text' || input.type === 'email') && input.value === '') {
            valid = false;
        } else if (input.type === 'checkbox' && input.checked === false) {
            valid = false;
        }

        // Help by adding "-" when writing account owner personal no.
        if (! isBackspace && (input.name === 'personalNo' || input.name === 'accountOwnerPersonalNo') && input.value.match(/^([0-9]{8})$/)) {
            input.value = input.value + '-';
        }
        // Help if member doesn't see that we added "-" and writes another one. :)
        if ((input.name === 'personalNo' || input.name === 'accountOwnerPersonalNo') && input.value.match(/^([0-9]{8})(--)$/)) {
            input.value = input.value.slice(0, -1);
        }

        // Help if member pastes or writes personal number, all numbers, without "-".
        if (! isBackspace && (input.name === 'personalNo' || input.name === 'accountOwnerPersonalNo') && input.value.match(/^([0-9]{12})$/)) {
            input.value = input.value.slice(0, 8) +'-'+ input.value.slice(8, 12);
        } 

        // Help by adding "-" when writing company identity no.
        if (! isBackspace && input.name === 'companyIdentityNo' && input.value.match(/^([0-9]{6})$/)) {
            input.value = input.value + '-';
        }
        // Help if member doesn't see that we added "-" and writes another one. :)
        if (input.name === 'companyIdentityNo' && input.value.match(/^([0-9]{6})(--)$/)) {
            input.value = input.value.slice(0, -1);
        }
        
        if ((input.name === 'personalNo' || input.name === 'accountOwnerPersonalNo') && ! input.value.match(/^([0-9]{8})-([0-9]{4})$/)) {
            valid = false;
        }

        if (input.name === 'companyIdentityNo' && ! input.value.match(/^([0-9]{6,8})-([0-9]{4})$/)) {
            valid = false;
        }

        if (input.type === 'email' && ! input.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            valid = false;
        }

        /*if (input.name === 'checkOtherEmailContact') {
            valid = true; // Optional, all is good.
        }*/

        // Selecting signed by.
        if (input.type === 'radio' && input.name === 'signedBy') {
            // Signed by member we don't care if the following fields are empty.
            // Signed by other they are invalidated.
            let deleteKeys = [
                'signedByOtherType',
                'accountOwnerEmail',
                'accountOwnerPersonalNo',
                'accountOwnerFirstName',
                'accountOwnerLastName',
                'accountOwnerStreetAddress',
                'accountOwnerPostalCode',
                'accountOwnerPostalAddress',
            ];
            if (input.value === 'member') {
                deleteKeys.forEach((key) => {
                    if (typeof newState.invalid[key] !== 'undefined') {
                        delete newState.invalid[key];
                    }
                });
            } else {
                deleteKeys.forEach((key) => {
                    newState.invalid[key] = true;
                });
            }
        }

        // Selecting signed by other type.
        if (input.type === 'radio' && input.name === 'signedByOtherType') {
            // Signed by private we don't if the following fields are empty.
            // Signed by company they are invalidated.
            let deleteKeys = [
                'companyName',
                'companyIdentityNo'
            ];
            if (input.value === 'private') {
                deleteKeys.forEach((key) => {
                    if (typeof newState.invalid[key] !== 'undefined') {
                        delete newState.invalid[key];
                    }
                });
            } else {
                deleteKeys.forEach((key) => {
                    newState.invalid[key] = true;
                });
            }
        }

        // Remove invalid message for this field.
        delete newState.invalidMessage[input.name];

        if (valid === false) {
            newState.invalid[input.name] = true; // True = Is invalid.
        } else {
            delete newState.invalid[input.name]; // Remove key = Is valid.
        }

        if (input.type === 'checkbox') {
            newState.form[input.name] = input.checked;
        } else {
            newState.form[input.name] = input.value;
        }

        if (input.type === 'checkbox') {
            this.validateCheckboxes();
        }

        this.setState(newState);
    }
    
    /**
     * Validate checkboxes.
     */
    validateCheckboxes = () => {
        if (typeof this.state.invalid.checkAccountOwnerSignee !== 'undefined') {
            this.refCheckAccountOwnerSignee.current.classList.remove('is-valid');
            this.refCheckAccountOwnerSignee.current.classList.add('is-invalid');
        } else {
            this.refCheckAccountOwnerSignee.current.classList.remove('is-invalid');
            this.refCheckAccountOwnerSignee.current.classList.add('is-valid');
        }
        if (typeof this.state.invalid.checkTerms !== 'undefined') {
            this.refCheckTerms.current.classList.remove('is-valid');
            this.refCheckTerms.current.classList.add('is-invalid');
        } else {
            this.refCheckTerms.current.classList.remove('is-invalid');
            this.refCheckTerms.current.classList.add('is-valid');
        }
    }

    /**
     * Submit step 1 - Creating the EFT.
     */
    submitStep1 = () => {
        this.validateCheckboxes();

        let invalid = this.state.invalid;

        if (Object.keys(invalid).length) {
            this.setState({ invalid, hasSubmitted: true });
            Alert(i18next.t('Errors.EFT.INVALIDATION'));
            return;
        }

        this.resetFormStatus();

        this.setState(
            {
                hasSubmitted: true,
                isStep1Submitting: true, 
                isStep1Disabled: true,
                invalid 
            }, () => {
                api_post('/persons/' + this.context.person.personId.externalId + '/create-eft', this.state.form)
                    .then((resp) => {
                        let newState = this.state;
                        newState.isStep1Submitting = false;
                        newState.isStep2Disabled = false;

                        if (resp.success) {
                            newState.isStep1Done            = true;
                            newState.eftGuid                = resp.eft_guid;
                            newState.pdfPreview.image       = resp.image_view_url;
                            newState.pdfPreview.downloadUrl = resp.pdf_download_url;
                        } else {
                            Alert(i18next.t('Errors.'+ resp.error));
                            newState.isStep1Disabled = false;
                        }

                        this.setState(newState);
                    })
                    .catch((resp) => {
                        let newState = this.state;

                        if (resp.error === 'EFT.VALIDATION_ERROR' && typeof resp.error_messages !== 'undefined') {
                            this.handleValidationErrors(resp.error_messages);
                        } else {
                            if (resp.error === 'INVALID_BANK_INFO') {
                                newState.invalid.bankRegNumber = true;
                                newState.invalid.bankAccountNumber = true;
                                newState.invalidMessage.bankRegNumber = i18next.t('Errors.'+ resp.error);
                            }
                            Alert(i18next.t('Errors.'+ resp.error));
                        }

                        newState.isStep1Submitting = false;
                        newState.isStep1Disabled = false;
                        this.setState(newState);
                    });
            }
        );
    }

    /**
     * Handle validation errors for submitting. Should validate in React but if
     * the API return error messages they are handled here.
     */
    handleValidationErrors = (errorMessages) => {
        let invalid        = this.state.invalid;
        let invalidMessage = this.state.invalidMessage;

        Object.keys(errorMessages).forEach((fieldId) => {
            invalid[fieldId] = true;
            invalidMessage[fieldId] = errorMessages[fieldId].join(', ');
        });

        Alert(i18next.t('Errors.EFT.INVALIDATION'));
        this.setState({ invalid, invalidMessage });
    }

    /**
     * onLoad for the large PDF preview image.
     */
    pdfPreviewImageOnLoad = () => {
        let pdfPreview = this.state.pdfPreview;
        pdfPreview.imageLoaded = true;
        this.setState({ pdfPreview });
    }

    /**
     * Submit step 2 - Sign EFT.
     */
    submitStep2 = () => {
        this.setState(
            {
                isSignAborted: false,
                isStep2Submitting: true,
                isStep2Disabled: true,
                signStatusMessage: null
            }, 
            () => {
                api_post('/persons/' + this.context.person.personId.externalId + '/sign-eft/' + this.state.eftGuid)
                    .then((resp) => {
                        if (resp.success) {

                            ReactDOM.render(
                                <QRCodeSVG value={resp.qr_code} />,
                                document.getElementById('qrCode')
                              );
                              let newState = this.state;
                              newState.bankIdLink = resp.bankid_token;
                              this.setState(newState);
                            this.checkStep2Status();
                        } else {
                            Alert(i18next.t('Errors.'+ resp.error));
                        }
                    })
                    .catch((resp) => {
                        Alert(i18next.t('Errors.'+ resp.error));
                        this.setState({ isStep2Submitting: false, isStep2Disabled: false });
                    });
            }
        );
    }

    /**
     * Check step 2 status. Looping to check each 2s if anything has changed
     * with the BankID signing.
     */
    checkStep2Status = () => {
        // Aborted.
        if (this.state.isSignAborted) {
            return;
        }

        // Check status every 2s.
        setTimeout(() => {
            api_get('/persons/' + this.context.person.personId.externalId + '/sign-eft/' + this.state.eftGuid)
                .then((resp) => {
                    if (! this.state.isSignAborted) {
                        let newState = this.state;
                        
                        if (typeof resp.bankid_message !== 'undefined' && resp.bankid_message.length > 0) {
                            newState.signStatusMessage = resp.bankid_message;
                        } else {
                            newState.signStatusMessage = null;
                        }

                        if (resp.bankid_status === 'pending') {

                            ReactDOM.render(
                                <QRCodeSVG value={resp.qr_code} />,
                                document.getElementById('qrCode')
                              );

                            this.checkStep2Status();
                        } else if (resp.bankid_status === 'failed') {
                            newState.isStep2Submitting = false;
                            newState.isStep2Disabled = false;
                        } else if (resp.bankid_status === 'complete') {
                            newState.isStep2Submitting = false;
                            newState.isSignCompleted = true;
                        }

                        this.setState(newState);
                    }
                })
                .catch((resp) => {
                    if (! this.state.isSignAborted) {
                        Alert(i18next.t('Errors.'+ resp.error));
                        this.setState({ isStep2Submitting: false, isStep2Disabled: false });
                    }
                });
        }, 1000);
    }

    /**
     * Cancel step 2 - Cancel sign process.
     */
    cancelStep2 = () => {
        this.setState({ isSignAborted: true, isStep2Submitting: false, isStep2CancelSubmitting: true }, () => {
            api_delete('/persons/' + this.context.person.personId.externalId + '/sign-eft/' + this.state.eftGuid)
                .then((resp) => {
                    if (! resp.success) {
                        Alert(i18next.t('Errors.'+ resp.error));
                    }
                    this.setState({ isStep2CancelSubmitting: false, isStep2Disabled: false, signStatusMessage: null });
                })
                .catch((resp) => {
                    Alert(i18next.t('Errors.'+ resp.error));
                    this.setState({ isStep2CancelSubmitting: false, isStep2Disabled: false, signStatusMessage: null });
                });
        });
    }

    /**
     * Zoom on the PDF preview image.
     */
    zoomPDFPreview = () => {
        let pdfPreview = this.state.pdfPreview;

        // Sets the height of the PDF Preview window on first click.
        if (this.state.pdfPreview.zoomLevel === null) {
            pdfPreview.imageHeight = this.refPDFPreview.current.clientHeight;
        }

        let newZoomLevel = this.state.pdfPreview.zoomLevel + 1;
        if (newZoomLevel > 2) {
            newZoomLevel = 0;
        }
        pdfPreview.zoomLevel = newZoomLevel;

        this.setState({ pdfPreview });
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className={this.state.isStep1Submitting ? 'form-disabled' : ''}>

                        <h3>{t("Settings.EFT.Autogiro.Title")}</h3>
                        <p className="body-text-small mb-3">{t("Settings.EFT.Autogiro.Instructions")}:</p>
                        <ul className="body-text-small mb-5">
                            <li>{t("Settings.EFT.Autogiro.Requirements.0")}</li>
                            <li>{t("Settings.EFT.Autogiro.Requirements.1")}</li>
                            <li>{t("Settings.EFT.Autogiro.Requirements.2")}</li>
                            <li>{t("Settings.EFT.Autogiro.Requirements.3")}</li>
                        </ul>

                        <h4>{t("Settings.EFT.Autogiro.Form.Member.Title")}</h4>

                        <div className="row mb-5 justify-content-center">
                            <div className="col-12">
                                <ul className="list medium">
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text-small color-rock">{t("MemberInfo.Name")}</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="body-text-small color-black">{this.context.person.firstName} {this.context.person.lastName}</span>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        typeof this.context.person.socialSecurityNumber === 'string'
                                        ? (
                                            <li>
                                                <div>
                                                    <div>
                                                        <span className="body-text-small color-rock">{t("Settings.EFT.Autogiro.Form.Member.PersonalNo")}</span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="body-text-small color-black">{this.context.person.socialSecurityNumber}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                        : null
                                    }
                                    <li>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className="body-text-small color-rock">{t("MemberInfo.Address")}</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="body-text-small color-black d-flex ms-auto">
                                                    {
                                                        typeof this.context.person.address === 'object' &&
                                                        typeof this.context.person.address.address1 === 'string'
                                                        ? (
                                                            <div>{this.context.person.address.address1}<span className="me-2">,</span> </div>
                                                        ) : null
                                                    }
                                                    <div>
                                                        {
                                                            typeof this.context.person.address === 'object' &&
                                                            typeof this.context.person.address.zip === 'string'
                                                            ? (
                                                                <span>{this.context.person.address.zip}</span>
                                                            ) : null
                                                        }
                                                        {
                                                            typeof this.context.person.address === 'object' &&
                                                            typeof this.context.person.address.zipName === 'string'
                                                            ? (
                                                                <span>&nbsp;{this.context.person.address.zipName}</span>
                                                            ) : null
                                                        }
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        typeof this.context.personCommunication.email === 'string'
                                        ? (
                                            <li>
                                                <div>
                                                    <div>
                                                        <span className="body-text-small color-rock">{t("Settings.DetailsEmail")}</span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="body-text-small color-black">{this.context.personCommunication.email}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        ) : null
                                    }
                                    {
                                        typeof this.context.personCommunication.mobilePhoneNumber === 'string'
                                        ? (
                                            <li>
                                                <div>
                                                    <div>
                                                        <span className="body-text-small color-rock">{t("Settings.DetailsPhone")}</span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="body-text-small color-black">{this.context.personCommunication.mobilePhoneNumber}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </div>

                        {
                            typeof this.context.person.socialSecurityNumber === 'undefined'
                            ? (
                                <div>
                                    <h4>{t("Settings.EFT.Autogiro.Form.PersonalInfo.Title")}</h4>
                                    <div className="row">
                                        <div className="col-12  mb-lg-0">
                                            <InputField
                                               
                                                type="text"
                                                name="personalNo"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.personalNo === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.personalNo}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                                placeholder={
                                                    `${t("Settings.EFT.Autogiro.Form.PersonalInfo.PersonalNo")} (${t("Settings.EFT.Autogiro.Form.PersonalInfo.PersonalNoPlaceholder")})`

                                                }/>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        }

                        <h4>{t("Settings.EFT.Autogiro.Form.Bank.Title")}</h4>
                                    
                        <div className="row">
                            <div className="col-12">
                                <InputField
                                    type="text"
                                    name="bankName"
                                    hasSubmitted={this.state.hasSubmitted}
                                    isInvalid={(this.state.invalid.bankName === true ? true : false)}
                                    invalidMessage={this.state.invalidMessage.bankName}
                                    disabled={this.state.isStep1Disabled ? true : false}
                                    onChange={this.formFieldOnChange}
                                    placeholder={t("Settings.EFT.Autogiro.Form.Bank.Bank")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6 ">
                                <InputField
                                    type="text"
                                    name="bankRegNumber"
                                    hasSubmitted={this.state.hasSubmitted}
                                    isInvalid={(this.state.invalid.bankRegNumber === true ? true : false)}
                                    invalidMessage={this.state.invalidMessage.bankRegNumber}
                                    disabled={this.state.isStep1Disabled ? true : false}
                                    onChange={this.formFieldOnChange}
                                    placeholder={t("Settings.EFT.Autogiro.Form.Bank.RegNumber")}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <InputField
                                    placeholder={t("Settings.EFT.Autogiro.Form.Bank.AccountNumber")}
                                    type="text"
                                    name="bankAccountNumber"
                                    hasSubmitted={this.state.hasSubmitted}
                                    isInvalid={(this.state.invalid.bankAccountNumber === true ? true : false)}
                                    invalidMessage={this.state.invalidMessage.bankAccountNumber}
                                    disabled={this.state.isStep1Disabled ? true : false}
                                    onChange={this.formFieldOnChange}
                                />
                            </div>
                        </div>

                        <p className="body-text-small mb-5">
                            <InfoIcon className="color-primary"></InfoIcon> {t("Settings.EFT.Autogiro.Form.Bank.RegNumberInfo")}
                        </p>

                        <h4>{t("Settings.EFT.Autogiro.Form.Signee.Title")}</h4>

                        <div className="row" onChange={this.formFieldOnChange}>
                            <div className="col-12">
                                <div className="form-check form-check-inline body-text-small">
                                    <InputField
                                        label={t("Settings.EFT.Autogiro.Form.Signee.FilledSignedByMember")}
                                        type="radio"
                                        name="signedBy"
                                        value="member"
                                        hasSubmitted={this.state.hasSubmitted}
                                        isInvalid={(this.state.invalid.signedBy === true ? true : false)}
                                        invalidMessage={this.state.invalidMessage.signedBy}
                                        disabled={this.state.isStep1Disabled ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-check form-check-inline body-text-small">
                                    <InputField
                                        label={t("Settings.EFT.Autogiro.Form.Signee.FilledSignedByOther")}
                                        type="radio"
                                        name="signedBy"
                                        value="other"
                                        hasSubmitted={this.state.hasSubmitted}
                                        isInvalid={(this.state.invalid.signedBy === true ? true : false)}
                                        invalidMessage=""
                                        disabled={this.state.isStep1Disabled ? true : false}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            this.state.form.signedBy === 'other'
                            ? (
                                <div>
                                    <div className="row mb-4" onChange={this.formFieldOnChange}>
                                        <div className="col-12 col-md-10 col-xxl-8 col-lg-6">
                                            <div className="form-check form-check-inline body-text-small">
                                                <InputField
                                                    label={t("Settings.EFT.Autogiro.Form.Signee.FilledPrivatePerson")}
                                                    type="radio"
                                                    name="signedByOtherType"
                                                    value="private"
                                                    hasSubmitted={this.state.hasSubmitted}
                                                    isInvalid={(this.state.invalid.signedByOtherType === true ? true : false)}
                                                    invalidMessage={this.state.invalidMessage.signedByOtherType}
                                                    disabled={this.state.isStep1Disabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {/* Now allowing company sign now. But leaving the actual code.
                                        <div className="col-12 col-md-10 col-xxl-8 col-lg-6">
                                            <div className="form-check form-check-inline body-text-small">
                                                <InputField
                                                    label={t("Settings.EFT.Autogiro.Form.Signee.FilledCompany")}
                                                    type="radio"
                                                    name="signedByOtherType"
                                                    value="company"
                                                    hasSubmitted={this.state.hasSubmitted}
                                                    isInvalid={(this.state.invalid.signedByOtherType === true ? true : false)}
                                                    invalidMessage=""
                                                    disabled={this.state.isStep1Disabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>
                            )
                            : null
                        }

                        {
                            this.state.form.signedBy === 'other' && this.state.form.signedByOtherType !== null
                            ? (
                                <div className="mt-5">
                                    <h4>{t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.Title")}</h4>

                                    <p className="body-text-small mb-4">
                                        <InfoIcon className="color-primary"></InfoIcon> {t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.Info")}
                                    </p>

                                    <div className="row">
                                        <div className="col-12">
                                            <InputField
                    
                                                type="text"
                                                name="accountOwnerPersonalNo"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerPersonalNo === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerPersonalNo}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                                placeholder={
                                                    `${t("Settings.EFT.Autogiro.Form.PersonalInfo.PersonalNo")} (${t("Settings.EFT.Autogiro.Form.PersonalInfo.PersonalNoPlaceholder")})`

                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.FirstName")}
                                                type="text"
                                                name="accountOwnerFirstName"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerFirstName === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerFirstName}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.LastName")}
                                                type="text"
                                                name="accountOwnerLastName"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerLastName === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerLastName}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.Email")}
                                                type="email"
                                                name="accountOwnerEmail"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerEmail === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerEmail}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.StreetAddress")}
                                                type="text"
                                                name="accountOwnerStreetAddress"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerStreetAddress === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerStreetAddress}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.PostalCode")}
                                                type="text"
                                                name="accountOwnerPostalCode"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerPostalCode === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerPostalCode}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.ResponsibleForPayment.PostalAddress")}
                                                type="text"
                                                name="accountOwnerPostalAddress"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.accountOwnerPostalAddress === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.accountOwnerPostalAddress}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        }

                        {
                            this.state.form.signedBy === 'other' && this.state.form.signedByOtherType === 'company'
                            ? (
                                <div>
                                    <h4>{t("Settings.EFT.Autogiro.Form.CompanyInfo.Title")}</h4>
                                    <div className="row">
                                        <div className="col-12">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.CompanyInfo.BusinessName")}
                                                type="text"
                                                name="companyName"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.companyName === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.companyName}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputField
                                                placeholder={t("Settings.EFT.Autogiro.Form.CompanyInfo.OrgNumber")}
                                                type="text"
                                                name="companyIdentityNo"
                                                hasSubmitted={this.state.hasSubmitted}
                                                isInvalid={(this.state.invalid.companyIdentityNo === true ? true : false)}
                                                invalidMessage={this.state.invalidMessage.companyIdentityNo}
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        }

                        <div className="row mt-5 mb-4 body-text-small">
                            <div className="col">
                                <div className="input-group">
                                    <div className="form-check-switch">
                                        <input
                                            className="form-check-input-switch"
                                            type="checkbox"
                                            id="checkAccountOwnerSignee"
                                            name="checkAccountOwnerSignee"
                                            disabled={this.state.isStep1Disabled ? true : false}
                                            onChange={this.formFieldOnChange}
                                            ref={this.refCheckAccountOwnerSignee}
                                        />
                                        <label htmlFor="checkAccountOwnerSignee" className="form-check-label">
                                            {t("Settings.EFT.Autogiro.Form.Checks.SignedByAccountOwner")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row body-text-small">
                            <div className="col">
                                <div className="input-group">
                                    <div className="form-check-switch">
                                        <input
                                            className="form-check-switch"
                                            type="checkbox"
                                            id="checkTerms"
                                            name="checkTerms"
                                            disabled={this.state.isStep1Disabled ? true : false}
                                            onChange={this.formFieldOnChange}
                                            ref={this.refCheckTerms}
                                        />
                                        <label htmlFor="checkTerms" className="form-check-label">
                                            <Trans i18nKey="Settings.EFT.Autogiro.Form.Checks.AcceptTerms">
                                                I/we have read and accept <a href={getApiHost() + '/download-eft/blank'} target="_blank" rel="noreferrer">the conditions</a> for autogiro.
                                            </Trans>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* (Removed for now, might get added later).
                            this.state.form.signedBy === 'other'
                            ? (
                                <div className="row mb-4 body-text-small">
                                    <div className="col">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkOtherEmailContact"
                                                name="checkOtherEmailContact"
                                                value="1"
                                                disabled={this.state.isStep1Disabled ? true : false}
                                                onChange={this.formFieldOnChange}
                                            />
                                            <label htmlFor="checkOtherEmailContact" className="form-check-label">
                                                {t('Settings.EFT.Autogiro.Form.Checks.AcceptEmailContact')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        */}
            
                        {
                            this.state.isStep1Submitting
                            ? (
                                <div className="d-flex justify-content-center mb-4 mt-5"><Loading>{t('Settings.EFT.Autogiro.Form.Misc.FetchingDocument')}</Loading></div> 
                            )
                            : null
                        }
                        {
                            ! this.state.isStep1Done && ! this.state.isStep1Submitting
                            ? (
                                <div className="text-center mb-small mt-5">
                                    <button 
                                        className="btn secondary-btn color-secondary" 
                                        disabled={this.state.isStep1Disabled ? true : false}
                                        onClick={this.submitStep1}
                                    >{t('Settings.EFT.Autogiro.Form.Misc.Continue')}</button>
                                </div>
                            )
                            : null
                        }

                        {
                            this.state.isStep1Done
                            ? (
                                <div>
                                    <div className="row mb-4 mt-5">
                                        <div className="col-12 col-md-10 col-xxl-8 body-text-small">
                                            <h4>{t('Settings.EFT.Autogiro.Form.Misc.SignWithBankId')}</h4>
                                            <p className="body-text-small mb-4">{t('Settings.EFT.Autogiro.Form.Misc.SignWithBankIdInfo')}</p>
                                            <p className="body-text-small"><a href={this.state.pdfPreview.downloadUrl} target="_blank" rel="noreferrer">{t('Settings.EFT.Autogiro.Form.Misc.DownloadPdf')}</a></p>
                                        </div>
                                    </div>

                                    <div className="row mb-4 mt-4">
                                        <div
                                            style={{ height: (this.state.pdfPreview.imageHeight !== null ? this.state.pdfPreview.imageHeight + 'px' : 'auto') }}
                                            className={
                                                "col-12 col-md-10 col-xxl-8 eft-sign-pdf-preview" + 
                                                (this.state.pdfPreview.zoomLevel ? " zoom zoom-" + this.state.pdfPreview.zoomLevel : '') +
                                                (this.state.pdfPreview.imageLoaded ? " loaded shadow" : '')
                                            }
                                            onClick={this.zoomPDFPreview}
                                        >
                                            <img
                                                ref={this.refPDFPreview} 
                                                alt="pdf-preview" 
                                                className="pdf-preview"
                                                src={this.state.pdfPreview.image}
                                                onLoad={this.pdfPreviewImageOnLoad}
                                            />
                                            <div className="d-flex justify-content-center loading-wrapper"><Loading>{t('Settings.EFT.Autogiro.Form.Misc.LoadingPreview')}</Loading></div> 
                                        </div>
                                    </div>

                                    <div className="row mb-4 mt-5">
                                        <div className="text-center">
                                            <p className="body-text-small"></p>
                                        </div>
                                        {
                                            this.state.isStep2Submitting
                                            ? (
                                                <div id="bank-id-content">

                                                    <div id="qrCode"></div>

                                                    { !this.state.bankIdLink && (
                                                        <div className="d-flex justify-content-center mb-4 mt-4">
                                                            <Loading>Väntar på BankId</Loading>
                                                        </div>
                                                    )}

                                                    <div id="bank-id-holder">
                                                    { this.state.bankIdLink && (

                                                    <>
                                                        <p>
                                                        1. Öppna BankID appen på din telefon.<br />
                                                        2. Klicka på QR-kod ikonen i appen<br />
                                                        3. Rikta kameran mot QR-koden på skärmen<br />
                                                        </p>
                                                        <span>eller</span>
                                                        <a className="btn-bank-id btn secondary-btn color-primary mb-4 mr-4" href={'bankid:///?autostarttoken=' + this.state.bankIdLink + '&redirect=null'}>Öppna BankId</a>
                                                    </>
                                                    )}
                                                        <button 
                                                            className="btn"
                                                            disabled={this.state.isStep2CancelSubmitting ? true : false}
                                                            onClick={this.cancelStep2}
                                                        >{t('Settings.EFT.Autogiro.Form.Misc.Abort')}</button>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div className="text-center mt-4 mt-4">
                                                    <button 
                                                        className="btn secondary-btn color-secondary" 
                                                        disabled={this.state.isStep2Disabled ? true : false}
                                                        onClick={this.submitStep2}
                                                    >{t('Settings.EFT.Autogiro.Form.Misc.Sign')}</button>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.isStep2CancelSubmitting
                                            ? (
                                                <div>
                                                    <div className="d-flex justify-content-center mb-4 mt-5">
                                                        <Loading>{t('Settings.EFT.Autogiro.Form.Misc.CancellingSignProcess')}</Loading>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                        }
                                    </div>
                                </div>
                            )
                            : null
                        }

                        {
                            this.state.isSignCompleted
                            ? (
                                <div>
                                    <div className="row mb-4 mt-5">
                                        <div className="col-12 col-md-10 col-xxl-8 body-text-small">
                                            <h4>{t('Settings.EFT.Autogiro.Form.Misc.SignCompleted')}</h4>
                                            <p className="body-text-small">
                                                <Trans i18nKey="Settings.EFT.Autogiro.Form.Misc.SignCompletedInfo">
                                                    Signing completed. <a href={this.state.pdfPreview.downloadUrl} target="_blank" rel="noreferrer">Click here</a> to download a PDF copy of the signed document.
                                                </Trans>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        }
                    </div>
                )}
            </Translation>
        );
    }
}
