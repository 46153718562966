import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import {api_get, api_post} from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
import { Alert } from '../Alert.js';
//import { Modal } from '../Modal.js';
import { AutogiroForm } from './AutogiroForm.js';

export const Autogiro = (props) => {
    let context = useContext(PersonContext);
    let [ autogirolink, setAutogirolink ] = useState([]); // eslint-disable-line
    let [ isLoading, setLoading ] = useState(true);
    let [ isMounted, setIsMounted ] = useState(false);
    let location = useLocation();

    useEffect(() => {

        if(context?.person?.personId?.externalId == null) {
          return;
        }

        if(isMounted) {
          return;
        }

        setIsMounted(true);

        let status = new URLSearchParams(location.search).get("status");

        if (status && status === 'error') {
            Alert(i18next.t("Settings.AvtalegiroError"));
        } else if (status && status === 'cancel') {
            Alert(i18next.t("Settings.AvtalegiroCancel"));
        } else if (status && status === 'ok') {
            Alert(i18next.t("Settings.AvtalegiroOk"));
        }

        if (context.center.countrycode === 'SE') {
            setAutogirolink('#');
            setLoading(false);
        } else {
            api_get('/persons/' + context.person.personId.externalId + '/autogirolink')
                .then((resp) => {
                    setAutogirolink(resp.link);
                    setLoading(false);
                }
            );
        }
    }, [context]); // eslint-disable-line

    const memberReadyForAutogiro = () => {
        if (typeof context.person.address === 'undefined' ||
            typeof context.person.address.zip === 'undefined' ||
            typeof context.person.address.zipName === 'undefined' ||
            typeof context.person.address.address1 === 'undefined' ||
            typeof context.person.firstName === 'undefined' ||
            typeof context.person.lastName === 'undefined' ||
            typeof context.personCommunication.email === 'undefined' ||
            typeof context.personCommunication.mobilePhoneNumber === 'undefined'
        ) {
            return false;
        }

        return true;
    }

    const sentPaymentAgreementRequest = () => {
        // persons/{externalId}/payment_agreement/no
        setLoading(true);
        api_post(
            "/persons/" +
            context.person.personId.externalId +
            "/payment_agreement/no",
            {}
        )
            .then((resp) => {
                setTimeout(() => {
                    api_get('/persons/' + context.person.personId.externalId + '/autogirolink')
                        .then((resp) => {
                            setAutogirolink(resp.link);
                            if(resp.form) {
                                document.querySelector('#avtalegiro-form-holder').innerHTML = resp.form;
                                document.querySelector('#avtalegiro-form').submit();
                            } else {
                                window.location = resp.link;
                            }
                        }
                    );
                }, 5000);
                //props.redirect(autogirolink);
            })
            .catch((err) => {
                Alert("Errors.Unknown");
        });
    };

    return (
        <Translation>
            {(t) => (
                isLoading 
                ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading><div className="hide" id="avtalegiro-form-holder"></div></div>
                : (
                    <div className="mt-3">
                        <p className="body-text-small mb-small">{t("Settings.AutogiroInfo")}</p>
                        <h4>{t("Settings.AutogiroHeadline2")}</h4>

                        {
                            context.center.countrycode === 'SE'
                            ? (<p className="body-text-small mb-small">{t("Settings.AutogiroInfo1")}</p>)
                            : null
                        }

                        <p className="body-text-small mb-small">{t("Settings.AutogiroInfo2")}</p>

                        {
                            context.center.countrycode === 'SE'
                            ? (
                                <div className="d-flex flex-column">
                                    <div className="col">
                                        {
                                            memberReadyForAutogiro() === true
                                            ? (
                                                <AutogiroForm></AutogiroForm>
                                            )
                                            : (
                                                <div>
                                                    <h3>{t("Settings.EFT.Autogiro.NotReady.Title")}</h3>
                                                    <p className="body-text-small mb-3">{t("Settings.EFT.Autogiro.NotReady.Info")}</p>
                                                    <p className="body-text-small mb-3">{t("Settings.EFT.Autogiro.NotReady.Instructions")}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                            : (
                                <div className="d-flex flex-column">
                                    <div className="col col-md-6 mb-5">
                                        <a className="btn secondary-btn color-secondary" onClick={() => { // eslint-disable-line
                                            sentPaymentAgreementRequest();
                                        }}>{t("Settings.AutogiroOnline")}</a>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            )}
        </Translation>
    );
};
