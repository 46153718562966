import React, { useContext } from "react";

import PersonContext from "../../PersonContext.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { numberFormat } from "../../lib/numberFormat";
import { Invoices } from "./Invoices-fixed.js";

export const Subscription = ({ subscription }) => {
  const context = useContext(PersonContext);
  const { t } = useTranslation();
 

  const ClearingHouse = ({}) => {

    console.log(context.subscription.product.type);

    if (context.subscription.product.type === "EFT") {
      return t("Settings.InvoicesClearingHouseTypeEFT");
    } else if (context.subscription.product.type === "CREDIT_CARD") {
      return t("Settings.InvoicesClearingHouseTypeCREDITCARD");
    } else if (context.subscription.product.type === "INVOICE") {
      return t("Settings.InvoicesClearingHouseTypeINVOICE");
    } else if (context.subscription.product.type === "CASH") {
      return t("Settings.InvoicesClearingHouseTypeCASH");
    } else {
      return null;
    }
  };

  return (
    <div className="wrapper">
      <ul className="list medium mb-5">
        {context.subscription.bindingEndDate !== "" && (
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">{t("Settings.StopBindingTo")}</span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
               {context.subscription.bindingEndDate}
              </span>
            </div>
          </div>
        </li>
        )}
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Settings.ClipcardsBindingTo")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                {context.subscription.bindingPeriodLength === 0 &&
                  t("Subscriptions.NoBinding")}
                {context.subscription.bindingPeriodLength > 0 &&
                  t("Subscriptions.BindingDescription", {
                    bindingPeriodLength:
                      context.subscription.bindingPeriodLength,
                  })}
              </span>
            </div>
          </div>
        </li>
        {context.person.personType !== "PRIVATE" && (
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Subscriptions.PersonType")}{" "}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                {t("PersonTypeLabels." + context.person.personType)}
              </span>
            </div>
          </div>
        </li>
        )}
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("MemberInfo.Center")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">{context.center.name}</span>
            </div>
          </div>
        </li>

        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Subscriptions.AccessLevel")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                {t(context.subscription.cardArea)}
              </span>
            </div>
          </div>
        </li>

        {context.subscription &&
        typeof context.subscription.subscriptionVersion !== "undefined" &&
        typeof context.subscription.subscriptionVersion == 1 && // eslint-disable-line
        typeof context.subscription.product !== "undefined" ? (
          <li>
            <div>
              <div>
                <span className="body-text-small color-rock">
                  {t("MemberInfo.Membership")}
                </span>
              </div>
              <div className="text-end">
                <span className="body-text-small color-black">
                  {context.subscription.product.name}
                </span>
              </div>
            </div>
          </li>
        ) : (
          ""
        )}
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Settings.InvoicesDetailsPaymentMethod")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                <ClearingHouse/>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("MemberInfo.Membership")}
              </span>
            </div>
            <div className="text-end d-block flex-row justify-content-end">
              {context.subscription.cardIncludes.map((feature, index) => {
                return (
                  <span className="body-text-small" key={index}>
                    {feature}
                    {index < context.subscription.cardIncludes.length - 1 && (
                      <span className="me-1">, </span>
                    )}
                  </span>
                );
              })}
            </div>
          </div>
        </li>
      </ul>
      {context.subscription.product.type === "CASH" ? null : (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="body-text-small color-rock">
              {t("ChangeSubscription.PeriodPrice")}
            </span>
          </div>
          <div>
            <Price price={context.subscription.price} />
          </div>
        </div>
      )}
    </div>
  );
};

const Price = ({ price }) => {
  var countrycode = "NO";

  if (countrycode === "NO") {
    return (
      <span>
        <span className="h3">{Math.round(price)}</span>
        <span className="h3">kr/mnd</span>
      </span>
    );
  } else if (countrycode === "DE" || countrycode === "FI") {
    return (
      <span>
        <span className="h3">{numberFormat(price, 2, ".", "")}</span>
        <span className="h3">&euro;/Mon</span>
      </span>
    );
  } else {
    // SE
    return (
      <span>
        <span className="h3">{Math.round(price)}</span>
        <span className="h3">kr/mån</span>
      </span>
    );
  }
};
